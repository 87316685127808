import React, { useEffect, useState } from 'react';
import Navbar from './components/Navbar/navbar';
import { BrowserRouter, Route, useLocation, Switch, Redirect } from 'react-router-dom';
import Home from './components/Home/home';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Team from './components/Team/team';
import Career from './components/career/career'
import ContactUs from './components/contactUs/contactUs';
import UserInfo from './components/EarlyAccessUser/userInfo';
import Thankyou from './components/Thanks/Thankyou';
import Student from './components/Student/Student';
import Teacher from './components/Teacher/Teacher';

import SubmitQuestion from './components/SubmitQuestion/SubmitQuestion';
import Footer from './components/Footer/Footer';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import CreateClass from './components/CreateClass/CreateClass';
import CameraCapture from './components/UploadImage/UploadImage';
import { isNil } from 'lodash';
import LogRocket from 'logrocket';
import { ClientJS } from 'clientjs';
import Feedback from './components/Feedback/Feedback';
import PageNotFound from './components/PageNotFound/PageNotFound';
import Flyer from './components/Flyer/Flyer';
import TermsandCondition from './components/PrivacyPolicy/TermsandCondition';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import dashboard from './components/dashboard/dashboard';
import SignUpPlans from './components/SignUp/SignUpPlans';
import RequestDemo from './components/Teacher/RequestDemo';


function App() {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState('');
  const [isLogRocketInitialize, setIsLogRocketInitialize] = useState(false);

  useEffect(() => {
    setCurrentPage(location.pathname.toLowerCase());
    const client = new ClientJS();
    const fingerprint = client.getFingerprint();
    if (!isNil(LogRocket) && !isLogRocketInitialize) {
      // setupLogRocketReact(LogRocket);
      LogRocket.identify(fingerprint.toString(), {
        name: fingerprint,
        email: fingerprint + '@aiplato.ai',
      });
      LogRocket.init('zdohul/aiplato');
      setIsLogRocketInitialize(true);
    }
  }, [location]);
  return (

    <div className="main-container">

      <ToastContainer />
      {!currentPage.includes('/question') && !currentPage.includes('/termsandcondition') && !currentPage.includes('/privacypolicy') &&
        <Navbar />
      }
      <Switch>
        <Route path="/" exact component={Home} />
        {/* <Route path="/home" exact component={Home} /> */}
        <Route path="/earlyaccesspriorityinfo/:earlyaccessid?" exact component={UserInfo} />
        <Route path="/student" exact component={Student} />
        <Route path="/educator" exact component={Teacher} />
        <Route path="/team" exact component={Team} />
        <Route path="/careers" exact component={Career} />
        <Route path="/contact" exact component={ContactUs} />
        <Route path="/thankyou" exact component={Thankyou} />
        <Route path="/question" exact component={SubmitQuestion} />
        <Route path="/login" component={Login} />
        {/* <Route path="/signup/:useruniqueid?" component={SignUp} /> */}
        <Route path="/signup/:userplan?/:useruniqueid?" component={SignUp} />
        <Route path="/createclass" component={CreateClass} />
        <Route path="/upload" component={CameraCapture} />
        <Route path="/feedback" component={Feedback} />
        <Route path="/GSVsummitSpecial" component={Flyer} />
        <Route path="/PageNotFound" component={PageNotFound} />
        <Route path="/termsandcondition" exact component={TermsandCondition} />
        <Route path="/privacypolicy" exact component={PrivacyPolicy} />
        <Route path="/ptdashboard" exact component={dashboard} />
        <Route path="/signUpPlans/:useruniqueid?" component={SignUpPlans} />
        <Route path="/requestDemo" exact component={RequestDemo} />


        <Redirect to="/PageNotFound" ></Redirect>
      </Switch>

      {(!currentPage.includes('/question') && !currentPage.includes('/upload') && !currentPage.includes('/termsandcondition') && !currentPage.includes('/privacypolicy')) &&
        <div className="footer">
          <Footer></Footer>
        </div>
      }


    </div>
  );
}

export default App;
