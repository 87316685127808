import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import { Select, MenuItem } from "@mui/material";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { requestDemoDetailsSubmit } from '../../common/API';
import { isNil } from 'lodash';
import { showFullApp } from '../../common/Functions';



const RequestDemo = () => {
    // Define state variables for each form field
    const [requestSource, setRequestSource] = useState('');
    const [educatorName, setEducatorName] = useState('');
    const [instituteName, setInstituteName] = useState('');
    const [typeOfInstitute, setTypeOfInstitute] = useState('');
    const [noOfStudentInPilot, setNoOfStudentInPilot] = useState(10);
    const [courses, setCourses] = useState('');
    const [courseDuration, setCourseDuration] = useState(0);
    const [platformUsed, setplatformUsed] = useState('');
    const [aiTried, setaiTried] = useState('');
    const [requesterEmail, setRequesterEmail] = useState('')
    const [validRequesterEmail, setValidRequesterEmail] = useState(false)


    useEffect(() => {
        if (!isNil(window.location.search) && window.location.search !== '') {
            const queryString = window.location.search.slice(1);
            let queryParamsArry = queryString.split("=")[1];
            //     alert(queryParamsArry)
            setRequestSource(queryParamsArry)
        }
    }, [])

    // const hasValidValues = () => {
    //     let isValid = requesterEmail.trim().length > 0 && isValidEmail(requesterEmail.trim()) ? true : false
    //     return isValid
    // }

    const setandValidateRequesterEmail = (e) => {
        setRequesterEmail(e.target.value)
        if (e.target.value !== "") {
            let isValid = e.target.value.trim().length > 0 && isValidEmail(e.target.value.trim()) ? true : false
            setValidRequesterEmail(isValid)
        }
    }

    const isValidEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }

    const handleBack = () => {
        window.location.href = "/educator"
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validRequesterEmail) {
            const formData = {
                requestSource,
                requesterEmail,
                educatorName,
                instituteName,
                typeOfInstitute,
                noOfStudentInPilot,
                courses,
                courseDuration,
                platformUsed,
                aiTried,
            };
            // console.log(formData); // Log data for now
            // You can send this data to your backend or API here

            requestDemoDetailsSubmit(formData).then(res => {
                if (res.status === 200) {
                    if (requestSource === "pilot") {
                        toast.success("Thank you! Our team will contact you shortly to set up your pilot program.", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                    }
                    else {
                        toast.success("Thank you! Our team will reach out soon to schedule a demo.", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                    }

                    setTimeout(() => {
                        setRequestSource(requestSource)
                        setValidRequesterEmail("")
                        setRequesterEmail("")
                        setEducatorName("")
                        setInstituteName("")
                        setTypeOfInstitute("")
                        setNoOfStudentInPilot(10)
                        setCourses("")
                        setCourseDuration(0)
                        setplatformUsed("")
                        setaiTried("")
                    }, 1000);

                }
            }).catch(err => {
                toast.error("Error!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

                console.error(err.message)
            })
        }
        else {
            toast.error("Please enter valid email address.", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    return (

        <div className='fwidth'>
            <div className="banner-pt container-fluid bg-gray1 pb-3">
                <Container className="gradientBannerBg">
                    <div className="col-12 col-md-12 col-lg-12">
                        <div className='homeBannerHead1'>
                            <h2>
                                {requestSource === 'demo' ?
                                    'Schedule a Demo – AI Teaching Assistant for STEM'
                                    :
                                    'Request a Pilot – AI Teaching Assistant for STEM'
                                }</h2>
                            <h3 style={{ marginTop: '10px', marginBottom: '20px' }}>{
                                requestSource === "demo" ?
                                    'Experience AI-Powered Learning'
                                    :
                                    'Bring AI-Powered Learning to Your Classroom'
                            }
                            </h3>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="container-fluid bg-gray1">
                <Container className="secpad">
                    <Row className="">
                        <Col xs={12} md={12}>
                            <div style={{ marginTop: '-30px' }} className="copyText">
                                {requestSource === 'pilot' ?
                                    'Thank you for expressing interest in participating in aiPlato’s pilot program. We are committed to providing each pilot the resources necessary for maximum impact. To help us better understand your teaching context and ensure the pilot aligns with your needs, we ask you to kindly provide the following details:'
                                    :
                                    'The aiPlato Team is here for you! If you need a walk-through, group pricing, support, or have questions about AI, fill out this form, and we will be in touch.'
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="container-fluid px-0">
                <Container className='pt-2 md-5'>
                    <Box component="form" onSubmit={handleSubmit} sx={{ my: 1, mx: 'auto', width: ['100%', 750] }} className='whiteBG'>
                        {/* <Typography component="h1" variant="h5" sx={{ pb: 2 }}>
                            Request Demo Details
                        </Typography> */}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    autoFocus
                                    id="email"
                                    label="Your Email"
                                    name="email"
                                    autoComplete="email"
                                    value={requesterEmail}
                                    onChange={(e) => setandValidateRequesterEmail(e)}
                                    sx={{
                                        "& .MuiFormHelperText-root": {
                                            fontSize: "11px !important",
                                            color: !validRequesterEmail ? 'red !important' : 'gray !important'
                                        },
                                    }}
                                    helperText={!validRequesterEmail ? 'Invalid email format' : 'Highly recommended for support and password recovery'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <label>Educator Name : </label> */}
                                <TextField
                                    autoComplete="given-name"
                                    name="educatorName"
                                    fullWidth
                                    id="educatorName"
                                    label="Educator Name : "
                                    value={educatorName}
                                    onChange={(e) => setEducatorName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <label>Institute Name : </label> */}
                                <TextField
                                    autoComplete="given-name"
                                    name="instituteName"
                                    fullWidth
                                    id="instituteName"
                                    label="Institution Name : "
                                    value={instituteName}
                                    onChange={(e) => setInstituteName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <label>Type of Institute : </label> */}
                                <TextField
                                    autoComplete="given-name"
                                    name="typeOfInstitute"
                                    fullWidth
                                    id="typeOfInstitute"
                                    label="Type of Institution (University, Community College, K-12 Public, or K-12 Private) : "
                                    value={typeOfInstitute}
                                    onChange={(e) => setTypeOfInstitute(e.target.value)}
                                />
                            </Grid>
                            {requestSource === 'pilot' ?
                                <Grid item xs={12}>
                                    {/* <label>Number of Students Participating in the Pilot : : </label> */}
                                    <TextField
                                        style={{ color: 'gray !important' }}
                                        type='number'
                                        autoComplete="given-name"
                                        name="noOfStudentInPilot"
                                        fullWidth
                                        id="NoOfStudentInPilot"
                                        label="Number of Students Participating in the Pilot : "
                                        value={noOfStudentInPilot}
                                        onChange={(e) => setNoOfStudentInPilot(e.target.value)}
                                    />
                                </Grid>
                                :
                                null
                            }
                            <Grid item xs={12}>
                                {/* <label>Courses : </label> */}
                                <TextField
                                    autoComplete="given-name"
                                    name="courses"
                                    fullWidth
                                    id="courses"
                                    label="Courses : "
                                    value={courses}
                                    onChange={(e) => setCourses(e.target.value)}
                                />
                            </Grid>
                            {requestSource === 'pilot' ?
                                <>
                                    <Grid item xs={12}>
                                        {/* <label>Course Duration: </label> */}
                                        <Select
                                            style={{ color: 'gray' }}
                                            name="role"
                                            id="courseDuration"
                                            fullWidth
                                            onChange={(e) => setCourseDuration(e.target.value)}
                                            value={courseDuration}
                                            defaultValue={0}
                                        >
                                            <MenuItem key={0} selected value="0"> Select Course Duration </MenuItem>
                                            <MenuItem key={1} value="Semester">Semester</MenuItem>
                                            <MenuItem key={2} value="Quarter">Quarter</MenuItem>
                                            <MenuItem key={3} value="Academic Year">Academic Year</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <label>Current or Prior Online Homework Platforms Used : </label> */}
                                        <TextField
                                            autoComplete="given-name"
                                            name="platformUsed"
                                            fullWidth
                                            id="platformUsed"
                                            value={platformUsed}
                                            label="Current or Prior Online Homework Platforms Used (e.g., Mastering Physics) : "
                                            onChange={(e) => setplatformUsed(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <label>AI in Education Resources Tried, if Any : </label> */}
                                        <TextField
                                            autoComplete="given-name"
                                            name="aiTried"
                                            fullWidth
                                            id="aiTried"
                                            value={aiTried}
                                            label="AI in Education Resources Tried, if Any : "
                                            onChange={(e) => setaiTried(e.target.value)}
                                        />
                                    </Grid>
                                </>
                                :
                                null
                            }
                        </Grid>
                        {
                            showFullApp() ?
                                <Grid container spacing={8}>
                                    <Grid item xs={12} sm={3}>
                                        <Button onClick={handleBack} className='btnMain' type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Back </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Button onClick={handleSubmit} className='btnMain' type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Submit </Button>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container spacing={8}>
                                    <Grid item xs={12} sm={6}>
                                        <Button onClick={handleBack} className='btnMain' type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Back </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button onClick={handleSubmit} className='btnMain' type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Submit </Button>
                                    </Grid>
                                </Grid>
                        }
                    </Box>
                </Container>

                <ToastContainer />
            </div>
            {requestSource === 'pilot' ?
                <div style={{ marginTop: '35px', paddingTop: '20px' }} className="container-fluid bg-gray1">
                    <Container className="secpad">
                        <Row className="">
                            <Col xs={12} md={12}>
                                <div style={{ marginTop: '-30px' }} className="copyText">
                                    Please feel free to share any additional context or considerations for your class.
                                    Please send this information to us directly at <a href="mailto:pilot@aiplato.ai">pilot@aiplato.ai</a>.

                                    We value your experience as an educator and look forward to collaborating with you to bring cutting-edge AI tools into physics education. Please don’t hesitate to reach out if you have any questions or need further details about the program.
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                :
                null
            }
        </div>
    );
};

export default RequestDemo;
